import { defineStore } from 'pinia'
import { reactive } from 'vue'

export const useSocketStore = defineStore('socket_store', () => {
  const sockets = reactive<any>({})

  return {
    sockets,
  }
})
