<script setup lang="ts">
import { ref, watch } from 'vue'
import RightSideBar from '@/07-Shared/ui/RightSideBar.vue'
import FormItem from '@/components/supportComponents/FormItem.vue'
import { useTChatNotification } from '@/06-Entities/AdminInvoiceRemote/model/socket/useTChatNotification'

interface IEmits {
  (e: 'new', value: boolean): void
}
const emit = defineEmits<IEmits>()
const scrollBar = ref<HTMLElement | null>(null)
const { loading, messages, schema, showSidebar, invoiceId, sendMessage, newMsgReceived } =
  useTChatNotification(scrollBar)
watch(newMsgReceived, (value) => {
  emit('new', value)
})
</script>

<template>
  <el-button @click="showSidebar = !showSidebar">Открыть чат</el-button>
  <RightSideBar width="50%" style="overflow-y: auto" :title="`Чат по заказу ${invoiceId}`" v-model="showSidebar">
    <el-skeleton class="h-28 p-2" :rows="5" v-if="loading && messages.length === 0" animated />
    <div class="p-2 bg-gray" v-show="messages.length > 0" v-loading="loading">
      <el-scrollbar ref="scrollBar" height="660px">
        <div class="p-2 border" style="font-size: 12px; height: 660px">
          <div
            v-for="msg in messages"
            :key="msg.id"
            class="flex mb-1 leading-5"
            :class="msg.author_type === 'Client' ? '' : 'justify-end'"
          >
            <div class="p-2 border w-3/6" :class="msg.author_type === 'Client' ? 'bg-purple-light' : 'bg-green'">
              {{ msg.text }}
            </div>
          </div>
        </div>
      </el-scrollbar>
    </div>

    <el-empty description="В этом чате пока нет сообщений" v-if="!loading && messages.length === 0" />

    <div class="p-2" v-if="!loading">
      <el-form @submit.prevent="sendMessage" :validation-schema="schema" class="w-full">
        <el-row class="flex" :gutter="6">
          <el-col :lg="14">
            <FormItem name="text" v-slot="{ value, onBlur, onInput }" :label="''">
              <el-input
                :model-value="value"
                @blur="onBlur"
                @input="onInput"
                placeholder="Напишите сообщение"
                type="textarea"
                :rows="4"
                class="p-1"
              />
            </FormItem>
          </el-col>
          <el-col :lg="4">
            <el-button :disabled="loading" :loading="loading" type="primary" native-type="submit">Отправить</el-button>
          </el-col>
        </el-row>
      </el-form>
    </div>
  </RightSideBar>
</template>

<style scoped lang="sass"></style>
