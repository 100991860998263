import { defineComponent as _defineComponent } from 'vue'
import { unref as _unref, createVNode as _createVNode, withCtx as _withCtx, resolveComponent as _resolveComponent, openBlock as _openBlock, createBlock as _createBlock, createCommentVNode as _createCommentVNode, vShow as _vShow, withDirectives as _withDirectives } from "vue"

import { useStepper } from '@/entityes/invoice/useStepper'
import { DeclarationAction } from '@/entityes/invoice/declaration/declaration.type'
import { useInvoiceRepository } from '@/entityes/invoice/invoice.repository'
import { computed, watch, onUnmounted } from 'vue'
import { useRoute } from 'vue-router'
import { useAsyncInvoiceReload } from '@/entityes/invoice/useAsyncInvoiceReload'
import ClearLoader from '@/components/ClearLoader.vue'
import LeftSideBar from '@/components/LeftSideBar.vue'
import InvoiceSidebarMenu from '@/components/Invoice/InvoiceSidebarMenu.vue'
import { useInvoiceStore } from '@/entityes/invoice/invoice.store'
import InvoiceErrWarning from '@/components/Invoice/InvoiceErrWarning.vue'
import AdminInvoiceRemote from '@/05-Features/AdminInvoiceRemote/ui/AdminInvoiceRemote.vue'
import usePolitic from '@/use/usePolitic'
import { useWs } from '@/socket/ws'
import useNotificationsChannel from '@/socket/channels/useNotificatioinsChannel'


export default /*@__PURE__*/_defineComponent({
  __name: 'InvoiceView',
  setup(__props) {

const invoiceRepository = useInvoiceRepository()
const stepper = useStepper()
const { initAsync, hasTimer } = useAsyncInvoiceReload()
const { isAdmin } = usePolitic()
function init(id: string | string[]) {
  invoiceRepository
    .getInvoice(id as string)
    .then((inv) => {
      return initAsync(inv)
    })
    .then((res) => {
      const haveTms = res.tms
        ? stepper.checkingForTms(
            Object.entries(res.tms)
              .filter((item) => item[1])
              .map((item) => item[0] as DeclarationAction)
          )
        : false
      stepper.selectStep(res.status.invoice_status, haveTms)
    })
}

const route = useRoute()
const invoiceId = computed(() => route.params.id as string)
watch(invoiceId, init, { immediate: true })
const invoiceStore = useInvoiceStore()

const ws = useWs()
function socketInit() {
  ws.open()
  useNotificationsChannel(ws)
}

socketInit()
onUnmounted(() => {
  tNotificationChannel.unsubscribe()
  ws.close()
})

return (_ctx: any,_cache: any) => {
  const _component_el_col = _resolveComponent("el-col")!
  const _component_RouterView = _resolveComponent("RouterView")!
  const _component_el_row = _resolveComponent("el-row")!

  return (_openBlock(), _createBlock(_component_el_row, { gutter: 20 }, {
    default: _withCtx(() => [
      _createVNode(_component_el_col, {
        xs: 20,
        md: 5,
        lg: 4
      }, {
        default: _withCtx(() => [
          _createVNode(LeftSideBar, null, {
            default: _withCtx(() => [
              _createVNode(InvoiceSidebarMenu, {
                "invoice-id": invoiceId.value,
                "show-loader": _unref(invoiceRepository).getInvoice.indicator.value,
                "invoice-payed": _unref(invoiceStore).invoice?.status.invoice_status === 'payed'
              }, null, 8, ["invoice-id", "show-loader", "invoice-payed"])
            ]),
            _: 1
          })
        ]),
        _: 1
      }),
      _createVNode(_component_el_col, {
        xs: 24,
        sm: 24,
        md: 19,
        lg: 20,
        class: "py-4"
      }, {
        default: _withCtx(() => [
          (_unref(invoiceStore).invoice && _unref(isAdmin))
            ? (_openBlock(), _createBlock(AdminInvoiceRemote, {
                class: "mb-8",
                key: invoiceId.value
              }))
            : _createCommentVNode("", true),
          _createVNode(InvoiceErrWarning),
          _withDirectives(_createVNode(ClearLoader, null, null, 512), [
            [_vShow, _unref(hasTimer) || _unref(invoiceStore).invoice?.status.async]
          ]),
          _withDirectives(_createVNode(_component_RouterView, null, null, 512), [
            [_vShow, !(_unref(hasTimer) || _unref(invoiceStore).invoice?.status.async)]
          ])
        ]),
        _: 1
      })
    ]),
    _: 1
  }))
}
}

})