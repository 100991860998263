import { IUser, RefrethTokenResponse } from '@/entityes/user/user.type'
import { useUserStore } from '@/entityes/user/userStore'
import { UnauthorizedError } from '@/errors/FetchErrors'
import { useServiceStore } from '@/pinia/serviceStore'
import { ofetch } from 'ofetch'
import type { FetchError, FetchOptions, MappedResponseType, ResponseType } from 'ofetch'
import { ComputedRef, computed } from 'vue'
import { useRouter } from 'vue-router'

type fetchType = <T, R extends ResponseType = 'json'>(
  url: string,
  config?: FetchOptions<R>
) => Promise<MappedResponseType<R, T>>
export interface IRequestService {
  fullFetch: fetchType
  fetch: fetchType
  isProgress: ComputedRef<boolean>
}
export function useRequestService(): IRequestService {
  const userStore = useUserStore()
  const router = useRouter()
  const serviceStore = useServiceStore()
  function fetch<T, R extends ResponseType = 'json'>(url: string, config?: FetchOptions<R>) {
    return new Promise<MappedResponseType<R, T>>(async (resolve, reject) => {
      serviceStore.requestServiceInProcess = true
      if (!userStore.user) throw new UnauthorizedError()
      // проверяем что сессия еще жива
      if (!userStore.checkTokenActuality()) throw new UnauthorizedError()
      fullFetch<RefrethTokenResponse>('/users/change_token', {
        method: 'PATCH',
        body: {
          refresh_token: userStore.user.refresh_token,
        },
      })
        .then((newToken) => {
          if (!!userStore.user) {
            userStore.user.access_token = newToken.access_token
          }
          const headers = new Headers()
          headers.append('authorization', newToken.access_token)
          ofetch<T, R>(url, {
            ...config,
            baseURL: process.env.VUE_APP_SERVER_HOST + '/api',
            headers,
          })
            .then((r) => resolve(r))
            .catch((err: FetchError) => {
              // fixme обработка ошибок
              reject(err)
            })
            .finally(() => {
              serviceStore.requestServiceInProcess = false
            })
        })
        .catch(() => {
          userStore.signOut()
          router.push({
            name: 'authorization',
          })
        })
        .finally(() => {
          serviceStore.requestServiceInProcess = true
        })
    })
  }
  function fullFetch<T, R extends ResponseType = 'json'>(url: string, config?: FetchOptions<R>) {
    return new Promise<MappedResponseType<R, T>>((res, rej) => {
      serviceStore.requestServiceInProcess = true
      ofetch<T, R>(url, { ...config, baseURL: process.env.VUE_APP_SERVER_HOST + '/api' })
        .then((r) => res(r))
        .catch((err: FetchError) => {
          // FIXME обработка ошибок
          rej(err)
        })
        .finally(() => {
          serviceStore.requestServiceInProcess = false
        })
    })
  }
  const isProgress = computed(() => serviceStore.requestServiceInProcess)
  return {
    fetch,
    fullFetch,
    isProgress,
  }
}

export function useRequestServiceIndicator() {}
