import { ref, reactive, watch } from 'vue'
import { useUserStore } from '@/entityes/user/userStore'
import { ConnectionStatus } from '@/socket/sockets.type'
import { EventsCallback } from '@/socket/sockets.type'
import { IChannel } from '@/socket/sockets.type'
import { Obj } from '@/socket/sockets.type'

export const useWs = () => {
  const userStore = useUserStore()
  const socket = ref<WebSocket>()
  const connectionStatus = ref<ConnectionStatus>('closed')
  const channels = reactive<{ [key: string]: IChannel }>({})
  const messageQueue = reactive<string[]>([])
  function open() {
    if (!!userStore.user) {
      const host = (process.env.VUE_APP_SERVER_HOST as string).replace(/https|http/, 'wss') + '/cable'
      const url = `${host}?token=${userStore?.user.access_token.split(' ')[1]}`
      socket.value = new WebSocket(url)
      socket.value.onopen = () => {
        connectionStatus.value = 'connected'
      }
      socket.value.onmessage = (event) => {
        const responce = event.data
        const msg = JSON.parse(responce)
        const identifier = msg?.identifier ? msg?.identifier : undefined
        if (msg.type && !identifier) {
          console.log('ping')
        } else if (!msg.message) {
          const id = JSON.parse(identifier)
          channels[id.channel].events[msg.type]?.forEach((fn) => {
            fn(msg)
          })
        } else {
          const id = JSON.parse(identifier)
          const message = msg.message.message

          if (message) {
            channels[id.channel].events[msg.message.type]?.forEach((fn) => {
              fn(message)
            })
          }
        }
      }
      socket.value.onclose = () => {
        connectionStatus.value = 'closed'
      }
      socket.value.onerror = () => {
        connectionStatus.value = 'error'
      }
    }
  }

  function close() {
    socket.value?.close()
  }

  function newChannel(name: string) {
    const channel: IChannel = {
      name,
      arg: {},
      subscribed: false,
      events: {},
      on<T>(type: string, callback: EventsCallback<T>) {
        if (Object.keys(this.events).includes(type)) {
          this.events[type].push(callback)
        } else {
          this.events[type] = [callback]
        }
      },
      send() {},
      clearEvent(type) {
        this.events[type].splice(0, this.events[type].length)
      },
      subscribe(arg?: Obj<any>) {
        if (this.subscribed) this.unsubscribe()
        this.arg = arg ? arg : {}

        this.on('confirm_subscription', () => {
          console.log(this.name, 'subscribed')
        })

        _send(
          JSON.stringify({
            command: 'subscribe',
            identifier: JSON.stringify({
              channel: this.name,
              ...this.arg,
            }),
          })
        )
      },
      unsubscribe() {
        _send(
          JSON.stringify({
            command: 'unsubscribe',
            identifier: JSON.stringify({
              channel: this.name,
              ...this.arg,
            }),
          })
        )
      },
    }
    channels[name] = channel
    return channel
  }

  function getChannel(name: string) {
    if (Object.keys(channels).includes(name)) {
      return channels[name]
    } else {
      return newChannel(name)
    }
  }

  function _send(msg: string) {
    if (connectionStatus.value === 'connected') {
      socket.value?.send(msg)
    } else {
      messageQueue.push(msg)
    }
  }

  watch(connectionStatus, (val) => {
    if (val === 'connected' && messageQueue.length > 0) {
      messageQueue.forEach((msg) => {
        socket.value?.send(msg)
      })
      messageQueue.splice(0, messageQueue.length)
    }
  })

  return {
    socket,
    open,
    close,
    getChannel,
  }
}
