import { ref, toRef, watch } from 'vue'
import { ElNotification } from 'element-plus'
import { useRequestService } from '@/use/useRequestService'
import { useInvoiceStore } from '@/entityes/invoice/invoice.store'
import { toTypedSchema } from '@vee-validate/yup'
import * as yup from 'yup'
import { useForm } from 'vee-validate'
import { useSocketStore } from '@/socket/useSocketStore'
import { IMessage } from '@/socket/sockets.type'

export const useTChatNotification = (scrollBar?: any) => {
  const invoiceStore = useInvoiceStore()
  const { fetch } = useRequestService()
  const messages = ref<IMessage[]>([])
  const loading = ref<boolean>(false)
  const showSidebar = ref<boolean>(false)
  const newMsgReceived = ref(false)

  const schema = toTypedSchema(
    yup.object({
      text: yup.string().required(),
    })
  )
  const { handleSubmit, setFieldValue, setFieldTouched } = useForm({
    validationSchema: schema,
  })
  const tNotificationChannel = useSocketStore().sockets['NotificationsChannel']
  tNotificationChannel.on('confirm_subscription', () => {})

  tNotificationChannel.on('new_message', (responce: IMessage) => {
    messages.value.push(responce)
    newMsgReceived.value = true
    setTimeout(() => {
      scrollToBottom()
    }, 300)
  })

  function getMessages() {
    loading.value = true
    return fetch<{ messages: IMessage[] }>(`/admin/invoices/${invoiceStore.invoice?.id}/t_chat`)
      .then((res) => {
        messages.value.splice(0, messages.value.length, ...res?.messages)
      })
      .catch((err) => {
        ElNotification({
          title: 'Ошибка',
          message: `Не удалось получить список сообщений ${err}`,
          type: 'error',
        })
      })
      .finally(() => {
        setTimeout(() => {
          scrollToBottom()
        }, 300)
      })
  }
  function scrollToBottom() {
    if (scrollBar.value) {
      const raw = toRef(scrollBar.value.wrapRef)
      raw._value.scrollTop = raw._value.scrollHeight
      loading.value = false
    }
  }

  const sendMessage = handleSubmit((body: { text: string }) => {
    loading.value = true
    return fetch(`/admin/invoices/${invoiceStore.invoice?.id}/t_chat`, {
      method: 'post',
      body: body,
    })
      .then(() => {
        getMessages()
      })
      .catch((err) => {
        ElNotification({
          title: 'Ошибка',
          message: `Не удалось отправить сообщение ${err}`,
          type: 'error',
        })
      })
      .finally(() => {
        setFieldValue('text', '')
        setFieldTouched('text', false)
      })
  })

  watch(showSidebar, (val: boolean) => {
    if (val) {
      getMessages()
      document.body.classList.add('no-scroll')
      newMsgReceived.value = false
    } else {
      document.body.classList.remove('no-scroll')
    }
  })

  return {
    messages,
    showSidebar,
    loading,
    invoiceId: invoiceStore?.invoice?.id,
    schema,
    sendMessage,
    newMsgReceived,
  }
}
