<script setup lang="ts">
import { useInvoiceStore } from '@/entityes/invoice/invoice.store'
import InvoiceInfo from '@/06-Entities/AdminInvoiceRemote/ui/InvoiceInfo.vue'
import FileInInvoiceTab from '@/06-Entities/AdminInvoiceRemote/ui/FileInInvoiceTab.vue'
import AdminErrors from '@/06-Entities/AdminInvoiceRemote/ui/AdminErrors.vue'
import AdminWarnings from '@/06-Entities/AdminInvoiceRemote/ui/adminWarnings.vue'
import AdminRemote from '@/06-Entities/AdminInvoiceRemote/ui/AdminRemote.vue'
import { computed, ref } from 'vue'
import { WarningFilled } from '@element-plus/icons-vue'
import ExanteRemote from '@/06-Entities/AdminInvoiceRemote/ui/ExanteRemote.vue'
import TBankRemote from '@/06-Entities/AdminInvoiceRemote/ui/TBankRemote.vue'
import TBankChatFiles from '@/06-Entities/AdminInvoiceRemote/ui/TBankChatFiles.vue'
import TBankKassa from '@/06-Entities/AdminInvoiceRemote/ui/TBankKassa.vue'
import TBankChat from '@/06-Entities/AdminInvoiceRemote/ui/TBankChat.vue'

const invoiceStore = useInvoiceStore()
const hasErrors = computed(
  () => invoiceStore.invoice && Object.keys(invoiceStore.invoice?.warning_and_error.errors).length > 0
)
const invoiceWarnings = computed(() => invoiceStore.invoice && invoiceStore.invoice?.warning_and_error.warnings)
const showWarnings = {
  interactive_brokers_files: ['Shorts not in MTM'],
}
const exanteInvoice = computed(() => invoiceStore.invoice?.email === 'exante@exante.exante')
const tinkoffInvoice = computed(() => invoiceStore.invoice?.email === 'tinkoff@tinkoff.tinkoff')
const tinkoffPremiumInvoice = computed(() => invoiceStore.invoice?.email === 'external_premium@tinkoff.tinkoff')
const hasWarnings = computed(() => {
  if (invoiceStore.invoice) {
    return Object.keys(showWarnings).reduce((acc, key) => {
      const warnings = invoiceWarnings.value?.[key]
      for (const k in warnings) {
        showWarnings[key].forEach((warning: string) => {
          if (warning in warnings[k]) {
            acc = true
          }
        })
      }
      return acc
    }, false)
  }
})
const hasMsg = ref(false)
function setNotification(val: boolean) {
  console.log(val)
  hasMsg.value = val
}
</script>

<template>
  <el-tabs class="bg-gray p-4 select">
    <el-tab-pane label="Инфо по заказу">
      <invoice-info />
    </el-tab-pane>
    <el-tab-pane label="Файлы заказа">
      <file-in-invoice-tab />
    </el-tab-pane>
    <el-tab-pane>
      <template #label>
        <div class="flex items-center">
          <el-icon v-if="hasErrors" color="#FF9A9D" class="mr-1">
            <WarningFilled />
          </el-icon>
          <p>Ошибки</p>
        </div>
      </template>
      <admin-errors />
    </el-tab-pane>
    <el-tab-pane>
      <template #label>
        <div class="flex items-center">
          <el-icon v-if="hasWarnings" color="#E6A23C" class="mr-1">
            <WarningFilled />
          </el-icon>
          <p>Предупреждения</p>
        </div>
      </template>
      <admin-warnings />
    </el-tab-pane>
    <el-tab-pane label="Управление заказом">
      <admin-remote />
    </el-tab-pane>
    <el-tab-pane label="Изменить статус заказа Exante" v-if="exanteInvoice">
      <exante-remote />
    </el-tab-pane>
    <el-tab-pane label="Управление T-Bank" v-if="tinkoffInvoice || tinkoffPremiumInvoice">
      <t-bank-remote />
    </el-tab-pane>
    <el-tab-pane label="Чат T-Bank" v-if="tinkoffPremiumInvoice">
      <template #label>
        <!--        <el-popover :visible="hasMsg" placement="top" title="''" :width="200" content="Новое сообщение"> </el-popover>-->
        <div class="flex items-center">
          <el-icon v-if="hasMsg" color="#E6A23C" class="mr-1">
            <WarningFilled />
          </el-icon>
          <p>Чат T-Bank</p>
        </div>
      </template>
      <t-bank-chat @new="setNotification" />
    </el-tab-pane>
    <el-tab-pane label="Файлы чата T-Bank" v-if="tinkoffPremiumInvoice">
      <t-bank-chat-files />
    </el-tab-pane>
    <el-tab-pane label="Создать оплату T-Bank" v-if="tinkoffPremiumInvoice">
      <t-bank-kassa />
    </el-tab-pane>
  </el-tabs>
</template>

<style scoped lang="sass"></style>
